import React from "react";
import { extendTheme } from "@chakra-ui/react";
import "@fontsource-variable/open-sans";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/700.css";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { HomeScreen } from "./HomeScreen";
import { SignInScreen } from "./SignInScreen";
import { ProtectedRoute } from "./ProtectedRoute";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const theme = extendTheme({
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Open Sans', sans-serif`,
  },
  fontWeights: {
    normal: 500,
    bold: 700,
  },
});

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <Router>
          <AuthProvider>
            <Box minHeight="100vh">
              <Routes>
                <Route path="/signin" element={<SignInScreen />} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <HomeScreen />
                    </ProtectedRoute>
                  }
                />
                {/* Add other protected routes here */}
              </Routes>
            </Box>
          </AuthProvider>
        </Router>
      </ChakraProvider>
    </I18nextProvider>
  );
}

export default App;
