import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  CloseButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import {
  addTransaction,
  updateTransaction,
  deleteTransaction,
  Transaction,
  getCategories,
  addCategory,
  Category,
} from "./supabaseUtils";
import { CreatableSelect } from "chakra-react-select";

interface TransactionScreenProps {
  isOpen: boolean;
  onClose: () => void;
  mode: "add" | "edit";
  transaction?: Transaction;
  onTransactionAdded?: () => void;
  onTransactionUpdated?: () => void;
  onTransactionDeleted?: () => void;
  categoryType: "income" | "expense";
}

interface TransactionFormValues {
  category_id: string;
  amount: number;
  period: string;
}

export const TransactionScreen: React.FC<TransactionScreenProps> = ({
  isOpen,
  onClose,
  mode,
  transaction,
  onTransactionAdded,
  onTransactionUpdated,
  onTransactionDeleted,
  categoryType,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [categoryId, setCategoryId] = useState(transaction?.category_id || "");
  const [amount, setAmount] = useState(transaction?.amount.toString() || "");
  const [period, setPeriod] = useState(
    transaction?.period || getCurrentPeriod()
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [initialValues, setInitialValues] = useState<TransactionFormValues>({
    category_id: "",
    amount: 0,
    period: "",
  });
  const [, setCategories] = useState<Category[]>([]);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const fetchCategories = useCallback(async () => {
    const fetchedCategories = await getCategories(categoryType);
    setCategories(fetchedCategories);
    setCategoryOptions(
      fetchedCategories.map((cat) => ({ value: cat.id, label: cat.name }))
    );
  }, [categoryType]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (isOpen) {
      if (mode === "add") {
        const currentPeriod = getCurrentPeriod();
        setCategoryId("");
        setSelectedCategory(null); // Reset the selected category
        setAmount("");
        setPeriod(currentPeriod);
        setInitialValues({
          category_id: "",
          amount: 0,
          period: currentPeriod,
        });
      } else if (transaction) {
        const values = {
          category_id: transaction.category_id,
          amount: transaction.amount,
          period: transaction.period,
        };
        setInitialValues(values);
        setCategoryId(values.category_id);
        setSelectedCategory(
          categoryOptions.find(
            (option) => option.value === values.category_id
          ) || null
        );
        setAmount(values.amount.toString());
        setPeriod(values.period);
      }
      setIsSubmitted(false);
    }
  }, [isOpen, mode, transaction, categoryOptions]);

  useEffect(() => {
    const currentValues = {
      category_id: categoryId,
      amount: parseFloat(amount) || 0,
      period,
    };

    const isDataChanged =
      mode === "edit" &&
      (currentValues.category_id !== initialValues.category_id ||
        currentValues.amount !== initialValues.amount ||
        currentValues.period !== initialValues.period);

    setIsFormValid(
      categoryId !== "" &&
        amount !== "" &&
        !isNaN(parseFloat(amount)) &&
        parseFloat(amount) > 0 &&
        period !== "" &&
        (mode === "add" || isDataChanged)
    );
  }, [categoryId, amount, period, mode, initialValues]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!isFormValid) return;

    setIsSaving(true);
    let success = false;

    const values: TransactionFormValues = {
      category_id: categoryId,
      amount: Math.abs(parseFloat(amount)), // Always use absolute value
      period,
    };

    if (mode === "add") {
      success = await addTransaction({
        ...values,
        created_at: new Date().toISOString(),
      });
      if (success && onTransactionAdded) {
        onTransactionAdded();
      }
    } else if (mode === "edit" && transaction?.id) {
      const updatedTransaction = await updateTransaction(
        transaction.id,
        values
      );
      success = !!updatedTransaction;
      if (success && onTransactionUpdated) {
        onTransactionUpdated();
      }
    }

    setIsSaving(false);
    if (success) {
      onClose();
      toast({
        title: mode === "add" ? t("transactionAdded") : t("transactionUpdated"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: t("error"),
        description:
          mode === "add"
            ? t("transactionAddedError")
            : t("transactionUpdatedError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const showInvalidState = (field: string) => isSubmitted && field === "";

  const handleDelete = async () => {
    if (mode === "edit" && transaction?.id) {
      setIsDeleting(true);
      const success = await deleteTransaction(transaction.id);
      setIsDeleting(false);
      if (success) {
        onClose();
        if (onTransactionDeleted) {
          onTransactionDeleted();
        }
        toast({
          title: t("transactionDeleted"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: t("error"),
          description: t("transactionDeletedError"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setIsDeleteAlertOpen(false);
  };

  const openDeleteAlert = () => {
    setIsDeleteAlertOpen(true);
  };

  const closeDeleteAlert = () => {
    setIsDeleteAlertOpen(false);
  };

  const handleCategoryChange = async (newValue: any) => {
    console.log("newValue: ", newValue);
    if (newValue.__isNew__) {
      setIsAddingCategory(true);
      const categoryName = newValue.label;
      const newCategory = await addCategory(categoryName, categoryType);
      setIsAddingCategory(false);

      if (newCategory) {
        await fetchCategories();
        setCategoryId(newCategory.id);
        toast({
          title: t("categoryAdded"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: t("error"),
          description: t("categoryAddedError"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      setCategoryId(newValue.value);
      setSelectedCategory(newValue);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {mode === "add"
              ? categoryType === "income"
                ? t("addIncome")
                : t("addExpense")
              : categoryType === "income"
              ? t("updateIncome")
              : t("updateExpense")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl
                  isRequired
                  isInvalid={showInvalidState(categoryId)}
                >
                  <FormLabel>{t("category")}</FormLabel>
                  <CreatableSelect
                    options={categoryOptions}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    placeholder={t("selectOrAddCategory")}
                    formatCreateLabel={(inputValue) =>
                      t("addNewCategory", { category: inputValue })
                    }
                    isDisabled={isAddingCategory}
                  />
                </FormControl>
                <FormControl isRequired isInvalid={showInvalidState(amount)}>
                  <FormLabel>{t("amount")}</FormLabel>
                  <Input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired isInvalid={showInvalidState(period)}>
                  <FormLabel>{t("period")}</FormLabel>
                  <Input
                    type="month"
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                  />
                </FormControl>
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            {mode === "edit" && (
              <IconButton
                aria-label={t("deleteTransaction")}
                icon={<DeleteIcon />}
                colorScheme="red"
                mr="auto"
                onClick={openDeleteAlert}
                isLoading={isDeleting}
              />
            )}
            <Button ref={cancelRef} onClick={onClose} mr={3}>
              {t("cancel")}
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isSaving}
              isDisabled={!isFormValid}
            >
              {t("save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("deleteTransactionTitle")}
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                onClick={closeDeleteAlert}
              />
            </AlertDialogHeader>

            <AlertDialogBody>{t("deleteTransactionContent")}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteAlert} mr={3}>
                {t("cancel")}
              </Button>
              <Button colorScheme="red" onClick={handleDelete}>
                {t("delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

function getCurrentPeriod(): string {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  return `${year}-${month}`;
}
