import React from "react";
import {
  Box,
  Button,
  VStack,
  Heading,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { supabase } from "./supabaseClient";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import googleLogo from "./assets/logo_google.png";

export const SignInScreen: React.FC = () => {
  const toast = useToast();
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const { origin } = window.location;
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: origin,
        },
      });

      if (error) throw error;

      // The user will be redirected to Google for authentication.
      // After successful authentication, Supabase will redirect the user back to your app.
      // You'll need to handle this redirect in your app (usually in App.tsx or a separate auth callback component).
      login();
      navigate("/");
    } catch (error) {
      console.error("Error signing in with Google:", error);
      toast({
        title: "Error signing in",
        description: "An error occurred while signing in with Google.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxWidth="400px" margin="auto" mt={8}>
      <VStack spacing={4} align="stretch">
        <Heading as="h1" size="xl" textAlign="center">
          Entrar
        </Heading>
        <Button
          leftIcon={<Image src={googleLogo} alt="Google logo" boxSize="24px" />}
          onClick={handleGoogleSignIn}
          bg="white"
          color="gray.800"
          border="1px"
          borderColor="gray.300"
          _hover={{ bg: "gray.100" }}
          width="80%"
          mx="auto"
        >
          Entrar con Google
        </Button>
        <Text textAlign="center">
          Si no tienes una cuenta, se creará automáticamente.
        </Text>
      </VStack>
    </Box>
  );
};
