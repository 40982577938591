import React from "react";
import {
  Box,
  Flex,
  Heading,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

interface TopBarProps {
  title: string;
  userPhotoUrl?: string;
  onLogout: () => void;
  onToggleTheme: () => void;
  currentTheme: "light" | "dark";
}

export const TopBar: React.FC<TopBarProps> = ({
  title,
  userPhotoUrl,
  onLogout,
  onToggleTheme,
  currentTheme,
}) => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue("white", "gray.800");

  return (
    <Box
      bg={bgColor}
      px={4}
      py={2}
      shadow="md"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="lg">{title}</Heading>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={
              <Avatar
                size="sm"
                src={userPhotoUrl}
                bg="blue.500"
                color="white"
              />
            }
            variant="ghost"
            aria-label="User menu"
          />
          <MenuList>
            <MenuItem onClick={onToggleTheme}>
              {currentTheme === "light" ? (
                <>
                  <MoonIcon mr={2} />
                  {t("darkMode")}
                </>
              ) : (
                <>
                  <SunIcon mr={2} />
                  {t("lightMode")}
                </>
              )}
            </MenuItem>
            <MenuItem onClick={onLogout}>{t("logout")}</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};
