import { supabase } from "./supabaseClient";

export interface Transaction {
  id: string;
  user_id: string;
  category_id: string;
  amount: number;
  period: string;
  created_at: string;
  category_name?: string;
  category_type?: string;
}

export interface Category {
  id: string;
  user_id: string;
  name: string;
  type: string;
}

export async function addTransaction(
  transaction: Omit<Transaction, "id" | "user_id">
): Promise<boolean> {
  const user = (await supabase.auth.getUser()).data.user;
  if (!user) return false;

  const { status, error } = await supabase
    .from("transactions")
    .insert({ ...transaction, user_id: user.id });

  if (error) {
    console.error("Error adding transaction:", error);
    return false;
  }

  return status === 201;
}

export async function updateTransaction(
  id: string,
  transaction: Partial<Omit<Transaction, "id" | "user_id">>
): Promise<boolean> {
  const user = (await supabase.auth.getUser()).data.user;
  if (!user) return false;

  const { error, status } = await supabase
    .from("transactions")
    .update(transaction)
    .match({ id, user_id: user.id })
    .single();

  if (error) {
    console.error("Error updating transaction:", error);
    return false;
  }

  return status === 204;
}

export async function getTransactions(): Promise<Transaction[]> {
  const { data, error } = await supabase
    .from("transactions")
    .select(
      `
      *,
      categories:category_id (name, type)
    `
    )
    .order("created_at", { ascending: false });

  if (error) {
    console.error("Error fetching transactions:", error);
    return [];
  }

  return (
    data?.map((transaction) => ({
      ...transaction,
      category_name: transaction.categories.name,
      category_type: transaction.categories.type,
    })) || []
  );
}

export async function deleteTransaction(id: string): Promise<boolean> {
  const user = (await supabase.auth.getUser()).data.user;
  if (!user) return false;

  const response = await supabase.from("transactions").delete().eq("id", id);

  return response.status === 204;
}

export const getCategories = async (
  categoryType?: "income" | "expense"
): Promise<Category[]> => {
  let query = supabase.from("categories").select("*");

  if (categoryType) {
    query = query.eq("type", categoryType);
  }

  const { data, error } = await query;

  if (error) {
    console.error("Error fetching categories:", error);
    return [];
  }

  return data || [];
};

export const addCategory = async (
  name: string,
  type: string
): Promise<Category | null> => {
  const user = (await supabase.auth.getUser()).data.user;
  if (!user) return null;

  const { data, error } = await supabase
    .from("categories")
    .insert({ name, type, user_id: user.id })
    .select();

  if (error) {
    console.error("Error adding category:", error);
    return null;
  }

  return data && data.length > 0 ? data[0] : null;
};
