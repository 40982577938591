import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  VStack,
  Button,
  Text,
  Spinner,
  useToast,
  Heading,
  HStack,
  Divider,
  useColorMode,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TransactionScreen } from "./TransactionScreen";
import { getTransactions, Transaction } from "./supabaseUtils";
import { TopBar } from "./components/TopBar";
import { formatMoney } from "./utils/formatters";
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";
import { useAuth } from "./AuthContext";
import { supabase } from "./supabaseClient";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";

export const HomeScreen: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const { logout } = useAuth();
  const [isTransactionScreenOpen, setIsTransactionScreenOpen] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);
  const [userPhotoURL, setUserPhotoURL] = useState<string | null>(null);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [categoryType, setCategoryType] = useState<"income" | "expense">(
    "income"
  );
  const [currentPeriod] = useState(format(new Date(), "yyyy-MM"));

  useEffect(() => {
    const getUserPhoto = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUserPhotoURL(user?.user_metadata?.avatar_url || null);
    };
    getUserPhoto();
  }, []);

  const openTransactionScreen = (
    type: "income" | "expense",
    transaction?: Transaction
  ) => {
    setSelectedTransaction(transaction || null);
    setIsTransactionScreenOpen(true);
    setCategoryType(type);
  };

  const closeTransactionScreen = () => {
    setIsTransactionScreenOpen(false);
    setSelectedTransaction(null);
  };

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    try {
      const fetchedTransactions = await getTransactions();
      setTransactions(fetchedTransactions);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast({
        title: t("error"),
        description: t("errorFetchingTransactions"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [t, toast]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const groupTransactionsByPeriod = (transactions: Transaction[]) => {
    return transactions.reduce((acc, transaction) => {
      if (!acc[transaction.period]) {
        acc[transaction.period] = [];
      }
      acc[transaction.period].push(transaction);
      return acc;
    }, {} as Record<string, Transaction[]>);
  };

  const formatPeriod = (period: string) => {
    const date = parse(period, "yyyy-MM", new Date());
    return format(date, "MMMM yyyy", { locale: es });
  };

  const handleTransactionChange = useCallback(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const getCategoryName = useCallback(
    (transaction: Transaction) => {
      return transaction.category_name || t("unknownCategory");
    },
    [t]
  );

  const handleLogoutClick = () => {
    setIsLogoutDialogOpen(true);
  };

  const handleLogoutConfirm = async () => {
    setIsLogoutDialogOpen(false);
    try {
      await logout();
    } catch (error) {
      console.error("Error signing out:", error);
      toast({
        title: t("error"),
        description: t("errorSigningOut"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleLogoutCancel = () => {
    setIsLogoutDialogOpen(false);
  };

  const calculateExpensesByCategory = useCallback(() => {
    const currentPeriodTransactions = transactions.filter(
      (t) => t.period === currentPeriod && t.category_type === "expense"
    );
    const expensesByCategory = currentPeriodTransactions.reduce(
      (acc, transaction) => {
        const category = getCategoryName(transaction);
        if (!acc[category]) {
          acc[category] = 0;
        }
        acc[category] += Math.abs(transaction.amount);
        return acc;
      },
      {} as Record<string, number>
    );

    return Object.entries(expensesByCategory).map(([name, value]) => ({
      name,
      value,
    }));
  }, [transactions, currentPeriod, getCategoryName]);

  // Update the COLORS array with more distinct colors
  const COLORS = [
    "#FF6384", // Bright Pink
    "#36A2EB", // Bright Blue
    "#FFCE56", // Bright Yellow
    "#4BC0C0", // Teal
    "#9966FF", // Purple
    "#FF9F40", // Orange
    "#FF5A5E", // Red
    "#5AD3D1", // Light Teal
    "#A8B3C5", // Gray Blue
    "#FFA1B5", // Light Pink
  ];

  return (
    <Box>
      <TopBar
        title={t("homeTitle")}
        userPhotoUrl={userPhotoURL ?? undefined}
        onLogout={handleLogoutClick}
        onToggleTheme={toggleColorMode}
        currentTheme={colorMode}
      />
      <Box textAlign="center" fontSize="xl" pt="70px">
        <VStack spacing={6}>
          <HStack spacing={4}>
            <Button
              colorScheme="green"
              onClick={() => openTransactionScreen("income")}
            >
              {t("addIncome")}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => openTransactionScreen("expense")}
            >
              {t("addExpense")}
            </Button>
          </HStack>

          {isLoading ? (
            <Spinner />
          ) : (
            <VStack spacing={4} align="stretch" width="100%">
              <Box height="300px" width="100%">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={calculateExpensesByCategory()}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {calculateExpensesByCategory().map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value) => formatMoney(value as number)}
                    />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Box>

              {transactions.length === 0 ? (
                <Text>{t("noTransactions")}</Text>
              ) : (
                Object.entries(groupTransactionsByPeriod(transactions)).map(
                  ([period, periodTransactions]) => (
                    <Box key={period}>
                      <Heading as="h3" size="md" mb={2}>
                        {formatPeriod(period).charAt(0).toUpperCase() +
                          formatPeriod(period).slice(1)}
                      </Heading>
                      <VStack spacing={2} align="stretch">
                        {periodTransactions.map((transaction) => (
                          <Box
                            key={transaction.id}
                            py={2}
                            px={3}
                            borderWidth={1}
                            borderRadius="md"
                            cursor="pointer"
                            onClick={() =>
                              openTransactionScreen(
                                transaction.category_type as
                                  | "income"
                                  | "expense",
                                transaction
                              )
                            }
                          >
                            <HStack justifyContent="space-between">
                              <Text fontSize="md" fontWeight="normal">
                                {getCategoryName(transaction)}
                              </Text>
                              <Text
                                fontSize="md"
                                fontWeight="semibold"
                                color={
                                  transaction.category_type === "expense"
                                    ? "red.400"
                                    : "green.400"
                                }
                              >
                                {formatMoney(transaction.amount)}
                              </Text>
                            </HStack>
                          </Box>
                        ))}
                      </VStack>
                      <Divider my={3} />
                    </Box>
                  )
                )
              )}
            </VStack>
          )}
        </VStack>
        <TransactionScreen
          isOpen={isTransactionScreenOpen}
          onClose={closeTransactionScreen}
          mode={selectedTransaction ? "edit" : "add"}
          transaction={selectedTransaction || undefined}
          onTransactionAdded={handleTransactionChange}
          onTransactionUpdated={handleTransactionChange}
          onTransactionDeleted={handleTransactionChange}
          categoryType={categoryType}
        />
      </Box>
      <AlertDialog
        isOpen={isLogoutDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleLogoutCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("logoutConfirmation")}
            </AlertDialogHeader>
            <AlertDialogBody>{t("logoutConfirmationMessage")}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleLogoutCancel}>
                {t("cancel")}
              </Button>
              <Button colorScheme="red" onClick={handleLogoutConfirm} ml={3}>
                {t("logout")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
